@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

:root {
    --red: #e22027;
    --white: #fff;
    --text: #645b5b;
    --primary: rgba(205, 169, 109, 0.35);
    --secondary: #f5f5f5;
    --table-header: #e2e2e2;
    --gray: #d3d3d3;
    --crumb: #7e7e7e;
    --primaryBold: #cda96d;
    --menuBack: rgb(238, 232, 221);
    --sub-bg: #eff3f4;
}

body {
    height: 100vh;
    width: 100vw;
    background: var(--secondary);
}

* {
    padding: 0;
    margin: 0;
    scrollbar-width: thin;
    scrollbar-color: var(--gray) transparent;
    text-decoration: none !important;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.ant-menu-title-content {
    font-family: 'Inter', sans-serif !important;
}

.ant-btn > span {
    font-family: 'Inter', sans-serif !important;
}

.ant-typography,
input,
select {
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.main_container {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    /* border-radius: 100vh; */
}

::-webkit-scrollbar-track {
    margin-block: 5px;
    margin-inline: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: var(--gray);
    border-radius: 100vw;
}

.ant-table-thead > tr > .ant-table-cell {
    color: rgb(255, 255, 255) !important;
}

.ant-table-cell-row-hover {
    background-color: #f5f5f5 !important;
}

.header {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--secondary);
}

.ant-menu-item-active {
    background: var(--primary) !important;
    color: var(--text) !important;
}

.ant-menu-item-selected {
    color: var(--text) !important;
    background: var(--primary) !important;
}

.icon {
    font-size: 20px;
    color: var(--primaryBold);
    cursor: pointer;
}

.login-input {
    width: 343px;
    height: 40px;
}

/* .ant-tabs-tab-active{
  background-color: var(--primary) !important;

} */
.ant-menu-item-active > span > svg {
    color: var(--primary) !important;
}

.ant-menu-sub {
    background-color: var(--menuBack) !important;
}

/* .ant-tabs-tab-active>.ant-tabs-tab-btn{
  color: white !important;
} */

.ant-menu-item-selected > span > svg {
    color: var(--primary);
}

.outlet {
    height: calc(100vh - 96px);
    overflow-x: hidden;
    overflow-y: scroll;
    background: white !important;
}

.sidebar_sider {
    background: #eee8dd;
    padding-block: 20px;
    overflow-y: scroll;
}

.container {
    padding: 20px;
}

.sidebar_menu {
    height: 100%;
    border-right: 0;
    background: #EEE8DD;
}

.tox-statusbar__branding {
    display: none;
}


.ant-table-filter-column > .active {
    color: white !important;
}


.ant-input-group {
    height: 60px !important;
    color: 'white' !important;
}

.ant-input-group-addon, .offer_price .ant-input-number-group .ant-input-number-group-addon {
    color: white !important;
}

.ant-input-group > input {
    height: 60px !important;
    padding: 0 11px;
}

.offer_price .ant-input-number-input-wrap > input {
    height: 60px !important;
    padding: 0 11px;
}

.ant-input-wrapper > .ant-input {
    height: 60px !important;
}


.d-none {
    display: none;
}

.event-none {
    pointer-events: none;
}

.ant-radio-button-wrapper-checked {
    color: white !important;
    background-color: var(--primaryBold) !important;
}

.submit_btn {
    width: 249px;
    height: 53px;
}

.offerType .ant-select-selection-item {
    color: white !important;
}

.offerType .ant-select-arrow {
    color: white;
}

.labelDy label::after {
    content: '' !important;
}

.labelDy {
    margin-bottom: 0px;
}

.labelDy label {
    font-size: 14px !important;
}

.labelQuestion label {
    font-size: 17px !important;
}

.labelDy .ant-form-item-control-input-content {
    font-size: 12px !important;
}

/* dashboard */
.totalCard div.ant-skeleton {
    width: 100% !important;
}

.cardDiv {
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.deleteVideo .ant-card-body {
    padding: 10px 10px 5px 10px !important;
    border: 1px solid #cda96dab;
    border-radius: 0px;
    border-bottom: 0px;
}

.deleteVideo ul li {
    margin: 0 !important;
    background: #cda96d;
    padding: 5px;
}

.deleteVideo ul li .anticon {
    color: white !important;
}

#innerDiv {
    height: 500px;
    overflow: auto;
    background: #e3d2b6;
    padding: 50px;
}

#innerDiv div {
    /*margin-top: 5px;*/
    /*border-radius: 15px;*/
    /*background-color: #cda96d;*/
    /*padding: 5px;*/
    /*color: white;*/
    /*font-size: 16px;*/
}


.chat-message {
    max-width: 80%;
    margin-bottom: 0.5em;
    padding: 0.5em;
    border-radius: 0.5em;
}

.chat-message.left {
    background-color: #cda96d;
    margin-right: auto;
    width: 30%;
    color: #fff;
    padding: 13px 25px;
}

.chat-message.right {
    background-color: #cda96d;
    margin-left: auto;
    color: #fff;
    width: 30%;
    padding: 13px 25px;
}

.w-30 {
    width: 30%;
    text-align: right;
    font-size: 12px;
}

.w-30 .chat-username {
    color: blue;
    cursor: pointer;
}

.w-30 .chat-time {
    color: white;
    padding: 0px 0px 0px 10px;
}


.w-100 {
    width: 100%;
    text-align: right;
    font-size: 12px;
}

.w-100 .chat-username {
    color: blue;
    cursor: pointer;
}

.w-100 .chat-time {
    color: white;
    padding: 0px 0px 0px 10px;
}

.skeleton-width .ant-skeleton {
    width: 100% !important;
}

.customerTabSection .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab-active {
    background: #d6cab5 !important;
    border-radius: 10px;
}

.customerTabSection .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: black;
}

.customerTabSection .ant-tabs .ant-tabs-content-holder {
    border: none;
}

.accountInformCtrTab {
    border: 1px solid #dcdcdc;
    margin: 0px 20% 0px 0px;
    border-radius: 10px;
    padding: 40px 50px;
}

.customerTabSection .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
    background: transparent;
    border: none;
}

.customerTabSection .ant-tabs .ant-tabs-nav {
    background: #dcdcdc;
    padding: 20px;
    border-radius: 10px;
    height: 200px;
}

.accountInformCtr p {
    font-size: 18px;
    color: #797979;
    margin-bottom: 20px;
}

.addressBtns button {
    border: none;
    box-shadow: unset;
    background: transparent;
}

.addressCityCtr {
    line-height: 30px;
    margin: 15px 0px 50px 0px;
}

.addressCtr-common {
    border: 1px solid gainsboro;
    padding: 10px;
    position: relative;
    overflow: hidden;
    background: #dcdcdc0f;
    height: 100%;
}

.addressCtr-default {
    border: 1px solid #cda96d;
    padding: 10px;
    position: relative;
    overflow: hidden;
    background: #d3b27a59;
    height: 100%;
}

.addressBtns {
    position: absolute;
    bottom: 10px;
}

.ribbon span {
    width: 150px;
    height: 34px;
    top: 20px;
    right: -40px;
    position: absolute;
    display: block;
    background: #cda96d;
    font-size: 13px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    line-height: 34px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
}

.listingCard {
    display: flex;
}

.listingCard .ant-card-body {
    margin-bottom: 40px;
}

.viewMore {
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.viewMore button {
    flex-direction: row-reverse;
    display: flex;
}

.viewMore button .anticon {
    position: relative;
    top: 3px;
    left: 10px;
}


.chatfullCtr {
    background: #eee1cc;
    height: 60vh;
    overflow: auto;
    padding: 40px;
    margin: 40px 0px;
}

.ant-comment {
    position: relative;
}

.ant-comment-inner {
    display: flex;
    padding: 16px 0;
}

.ant-comment-avatar {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 12px;
    cursor: pointer;
}

.ant-comment-avatar img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.ant-comment-content {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-width: 1px;
    font-size: 14px;
    word-wrap: break-word;
}

.ant-comment-content-author > a,
.ant-comment-content-author > span {
    padding-right: 8px;
    font-size: 14px !important;
    line-height: 18px;
}

.ant-comment-content-author-name a {
    color: rgba(0, 0, 0, .45) !important;
    font-size: 14px !important;
}

.ant-comment-content-author-time a {
    color: #ccc !important;
    white-space: nowrap;
    cursor: auto;
}

.ant-comment-content-detail p {
    white-space: pre-wrap;
}

.leftSideComment {
    width: 52%;
}

.rightSideComment {
    width: 52%;
    float: right;
}

.ant-comment-content-detail {
    background: #cda96d;
    color: white;
    padding: 10px;
    border-radius: 10px;
    line-height: 25px;
    margin: 5px 0px;
}

.viewheaderCtr {
    margin: 50px 0px 20px 0px;
}

.viewheaderCtr .ant-typography {
    color: black;
    margin-bottom: 0px;
}

.viewheaderCtr .ant-divider {
    margin: 10px 0;
}

.detailsCtr-head {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
}

.detailsCtr form .labelDy .ant-form-item-label label {
    color: #667085;
}

.detailsCtr .labelDy .ant-form-item-control-input-content {
    color: #667085;
}

.detailsCtr form .labelDy {
    line-height: 40px;
}

.detailsCtr .status-label .ant-form-item-control-input-content span {
    background: rgba(101, 216, 31, 0.17);
    border-radius: 10px;
    color: #22C55E;
    padding: 7px 10px;
}

.detailsCtr .status-label-1 .ant-form-item-control-input-content span {
    background: #22C55E;
    border-radius: 10px;
    color: #fff;
    padding: 7px 10px;
}

.ordersummaryCtr {
    background: rgba(205, 169, 109, 0.35);
    padding: 30px;
    margin: 40px 0px
}

.totalRecords {
    padding: 10px;
    font-weight: bold;
    color: #cda96d;
}


.paypal-status-success .anticon-check-circle {
    font-size: 140px;
    color: #3c955a;
}

.paypal-status-failed .anticon-close-circle {
    font-size: 140px;
    color: #c95454;
}

.paypal-status-failed h3 {
    font-size: 26px;
    margin: 20px 0px;
}

.paypal-status-failed .nav-link-filled {
    display: inline-block;
    border: 2px solid #cda96d;
    border-radius: 4px !important;
    height: 34px;
    line-height: 30px !important;
    padding: 0 18px !important;
    background-color: #cda96d;
    color: #fff;
    font-size: 14px !important;
    font-weight: 600 !important;
}

.paypal-status-success h3 {
    font-size: 26px;
    margin: 20px 0px;
}

.paypal-status-success a {
    display: inline-block;
    border: 2px solid #cda96d;
    border-radius: 4px !important;
    height: 34px;
    line-height: 30px !important;
    padding: 0 18px !important;
    background-color: #cda96d;
    color: #fff;
    font-size: 14px !important;
    font-weight: 600 !important;
    margin-top: 20px;
}


.listingPopup {
    height: 90vh;
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
}

.chartReportCard.ant-card {
    border: 1px solid #d9d9d9;
}

.requiredClass {
    color: #ff4d4f;
    font-size: 20px;
}


/* chart style */
.apexcharts-grid-borders line {
    display: none;
}

.apexcharts-grid-borders line:last-child {
    display: block;
}

.apexcharts-graphical line:first-child {
    display: block;
}

.apexcharts-graphical line {
    display: none;
}

.apexcharts-grid-borders line:first-child {
    display: none;
}

.apexcharts-gridlines-horizontal {
    display: none;
}

text {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
}

.chartReportCard .ant-card-body {
    margin-bottom: 0px;
}

/* invoice */


.wg-invoice {
    height: 100%;
    box-shadow: rgba(99, 99, 99, 0.31) 0px 2px 8px 0px;
    border-radius: 4px;
}

.wg-invoice .invoice-header {
    background-color: #e5dfd6;
    border-radius: 4px 4px 0px 0px;
    padding: 0px 40px 40px;
    margin-bottom: 40px;
}

.wg-invoice .invoice-header .invoice-logo {
    display: flex;
    justify-content: start;
    align-items: center;
}

.wg-invoice .invoice-header .invoice-logo > div {
    height: 100px;
    width: 160px;
    text-align: center;
}

.wg-invoice .invoice-header .invoice-logo > div img {
    height: 94px;
}

.wg-invoice .invoice-header .invoice-no {
    display: flex;
    justify-content: end;
    align-items: center;
    height: 100px;
    width: 100%;
}

.wg-invoice .invoice-header .invoice-no > p {
    padding: 0;
    margin: 0;
    font-size: 14px;
    color: #737373;
}

.wg-invoice .invoice-header .invoice-address-title {
    height: 30px;
    display: flex;
    position: relative;
}

.wg-invoice .invoice-header .invoice-address-title > span {
    height: 30px;
    line-height: 30px;
    background-color: #e5dfd6;
    z-index: 99;
    font-size: 15px;
    font-weight: 600;
    color: #CDA96D;
    text-transform: uppercase;
}

.wg-invoice .invoice-header .invoice-address-title > span:first-child {
    padding-right: 20px;
}

.wg-invoice .invoice-header .invoice-address-title > span:last-child {
    padding-left: 20px;
}

.wg-invoice .invoice-header .invoice-address-title::before {
    content: "";
    position: absolute;
    left: 10px;
    right: 10px;
    height: 1px;
    top: 14px;
    background-color: #CDA96D;
}

.wg-invoice .invoice-header .invoice-address-title > span:last-child {
    margin-left: auto;
}

.wg-invoice .invoice-header ul li {
    font-size: 14px;
    color: #737373;
    font-weight: 500;
}

.wg-invoice .invoice-header ul li.bolder {
    font-size: 16px;
    color: #000;
    font-weight: 500;
}

.wg-invoice .invoice-content {
    padding: 0px 40px 40px;
    margin-bottom: 40px;
}

.wg-invoice .invoice-footer {
    padding: 0px 40px 0px;
    margin-bottom: 25px;
}

.wg-invoice .invoice-content table {
    width: 100%;
}

.wg-invoice .invoice-content table thead th {
    text-transform: uppercase;
    font-size: 12px;
    color: #737373;
    padding: 10px 0px;
    text-align: right;
}

.wg-invoice .invoice-content table tbody td {
    font-size: 16px;
    color: #000;
    font-weight: 500;
    padding: 5px 0px;
    text-align: right;
}

.wg-invoice .invoice-content table tbody th.text-end {
    text-transform: uppercase;
    font-size: 12px;
    color: #737373;
}

.wg-invoice .invoice-content table thead th:first-child,
.wg-invoice .invoice-content table tbody td:first-child {
    text-align: left;
}

.wg-invoice .invoice-footer .footer-title {
    font-size: 20px;
    color: #000;
    font-weight: 500;
    margin-bottom: 30px;
}

.wg-invoice .invoice-footer ul {
    display: flex;
    justify-content: end;
}

.wg-invoice .invoice-footer ul li {
    font-size: 12px;
    margin-left: 10px;
    padding-left: 10px;
    position: relative;
}

.wg-invoice .invoice-footer ul li:before {
    content: "";
    position: absolute;
    top: 7px;
    left: -1px;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: #c5c5c5;
}

.wg-invoice .invoice-footer p {
    font-size: 12px;
    padding: 0;
    margin: 0;
}

.wg-invoice .seller-address h4 {
    text-align: right;
    font-size: 15px;
    font-weight: 600;
    color: #CDA96D;
    text-transform: uppercase;
}

.wg-invoice .seller-address address {
    text-align: right;
    font-size: 14px;
    color: #737373;
    font-weight: 500;
    margin: 0;
}

.text-end {
    text-align: right !important;
}

.orderButton {
    padding: 2px;
    margin-bottom: 10px;
    border-color: #CDA96D;
    color: #fff;
    border-radius: 4px;
}

.uploadDesign .ant-upload-list-item-container {
    margin-bottom: 31px !important;
}

.ant-form-item-explain .ant-form-item-explain-error{
    font-size: 14px !important;
}