.listing_wrapper {
    /* background-color: var(--background); */
    display: flex;
    flex-direction: column;
    gap: 70px;
    font-size: 25px;
    padding: 20px;
    background-color: var(--secondary);
}

.listing_box {
    background-color: white;
    width: 100%;
    padding: 40px;
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.listing_box_down {
    background-color: white;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 20px;
    align-items: center;
    justify-content: center;
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.listing_title {
    font-size: 22px;
    color: var(--primaryBold);
}

.listing_box_footer {
    width: 100%;
    text-align: center;
}

.listing_box_footer > span {
    color: var(--primaryBold);
}

.upload_listing {
}

.upload_pictures,
.upload_sizes,
.upload_design,
.upload_apperance,
.upload_style,
.upload_about,
.upload_offer {
    background-color: var(--background);
    padding: 10px;
    border-radius: 10px;
}

.upload_product_list {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-around;
}

.upload_size_select {
    width: 300px;
}

.upload_product {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 236px;
    /* width: 257px; */
    background-color: #d3d1d4;
}

.upload_image {
    display: flex;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.upload_image .btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload_about {
    display: flex;
    /* justify-content: space-around; */
    flex-wrap: wrap;
}

.style_img {
    height: 114px;
}

.silhouette_wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    /* justify-content: space-around; */
}

.silhouette_photo {
    height: 100%;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    /* width: 229px; */
    display: flex;
    flex-direction: column;
    align-items: center;

    /* justify-content: space-around; */
    /* border: 1px solid #999999; */
}

.silhouette_photo_1 {
    /* height: 114px; */
    /* width: 119px; */
    display: flex;
    position: relative;
    padding: 10px;
    flex-direction: column;
    align-items: center;
    text-align: center;

    /* justify-content: space-around; */
    /* border: 1px solid #999999; */
    cursor: pointer;
}

.silhouette_photo_active {
    border: 4px solid var(--primaryBold);
    /* color: white; */
    /* background-color: var(--primaryBold); */
}

.silhouette_photo_1:hover {
    border: 4px solid var(--primaryBold);
    /* background-color: var(--primaryBold); */
    /* color: var(--primaryBold); */
    /* color: white; */
}

.silhouette_photo:hover {
    border: 1px solid var(--primaryBold);
    background-color: var(--primaryBold);
    color: white;
}

.silhouette_photo_1 > img {
    height: 100%;
    width: 100%;
}

.silhouette_photo > img {
    height: 111px;
    width: 114px;
}

.text_area {
    height: 212px !important;
}

.offer_price {
    height: 59px;
    display: flex;
    align-items: center;
}



.listing-usd-input{
  border-radius: none;
}

.listing-usd-input > .ant-input-affix-wrapper {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    display: flex;
    background-color: var(--primaryBold);
}

.price {
    height: 100px;
    align-items: center;
}

.price_input {
    height: 59px;
    display: flex;
    align-items: center;
}

.price_prefix_bg {
    background-color: var(--primaryBold);
    padding: 20px;
    color: white;
}


.add_listing_returns>.ant-form-item>.ant-form-item-row>.ant-form-item-label>label{
    display: flex;
    color: #CDA96D;
    font-weight: 600;
    font-size: 24px;
}

.listing_wrapper .ant-upload-list-item-actions,
.listing_wrapper .ant-upload-wrapper .ant-upload-list .ant-upload-list-item::before{
    display: none !important;
}
.listing_wrapper .ant-upload-wrapper.ant-upload-picture-card-wrapper.uploadDesign{
    min-height: 300px;
}

/*.listing-upload>.ant-upload-list-picture-card{
    width: 100% !important;
    * display: flex !important; */
/* gap: 50px !important; */
/* flex-wrap: wrap !important; *
}

.listing-upload>.ant-upload-list-item-container, .ant-upload-select{
    height: 236px !important;
    width: 23% !important;
}*/
